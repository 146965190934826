<template>
  <div class="rays_tuner_simplifytool_highlight">
    <a-carousel
      arrows
      v-if="picStat == 2"
      class="pic_list"
      :afterChange="carouselChange"
      :dots="false"
    >
      <template #prevArrow>
        <div class="custom-slick-arrow" style="left: 10px">
          <svg-icon icon-class="chevron-left" class="icon" />
        </div>
      </template>
      <template #nextArrow>
        <div class="custom-slick-arrow" style="right: 10px">
          <svg-icon icon-class="chevron-right" class="icon" />
        </div>
      </template>
      <div v-for="(item, index) in pics" :key="index">
        <a-image
          :width="240"
          :height="136"
          :preview="false"
          :src="item.variant.url || ''"
          :fallback="imgSvg"
        />
      </div>
    </a-carousel>
    <div class="pic_bot" v-if="picStat == 2">
      <div class="pic_title">
        Highlight ({{ picNo + 1 }}/{{ pics.length }})
        <a-tooltip placement="bottomRight" :destroyTooltipOnHide="true">
          <template #title>
            <span>点击进行原图对比</span>
          </template>
          <svg-icon icon-class="contrast" class="icon" @click="openPic" />
        </a-tooltip>
      </div>
    </div>
    <div class="pic_none">
      <a-spin :spinning="picStat == 1" class="pic_loading" />
      <a-button
        class="none_btn"
        v-if="picStat == 0"
        @click="getHighlightList()"
        >{{ btnText }}</a-button
      >
    </div>
    <a-popover
      v-model:visible="popVisible"
      trigger="click"
      placement="left"
      color="rgba(0, 0, 0, .8)"
      :destroyTooltipOnHide="true"
      overlayClassName="simplifytool_highlight_pop"
    >
      <template #content>
        同时作为 Shader封面 显示
        <a @click="popHide" class="hide_btn">知道了</a>
      </template>
      <div class="pop_con"></div>
    </a-popover>
    <a-image-preview-group>
      <div class="contrast" id="rays_tuner_simplifytool_contrast">
        <a-image
          :width="2"
          :height="2"
          class="pic_reference"
          :src="((picOris[picNo] || {}).reference || {}).url || ''"
          :fallback="imgSvg"
        />
      </div>
      <div class="contrast">
        <a-image
          :width="2"
          :height="2"
          :src="((picOris[picNo] || {}).variant || {}).url || ''"
          :fallback="imgSvg"
        />
      </div>
    </a-image-preview-group>
  </div>
</template>
<script>
import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
  computed,
  watch,
} from 'vue';
import store from '@/vuex/store';
import { message } from 'ant-design-vue';
import api from '@/api/shader-tools';
import { getBaseUrl } from '@/api/service';
const engineurl = getBaseUrl('engine');

export default defineComponent({
  name: 'SimplifyHighlight',
  setup() {
    let imgSvg =
      'data:image/svg+xml;utf8,<svg width="240" height="136" viewBox="-40 -40 100 100" fill="gray" ' +
      'xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" ' +
      'd="M19 17.834C19 18.478 18.552 19 18 19H6L13.566 12.183C13.812 11.938 14.258 11.939 14.499 ' +
      '12.182L19 16.66V17.834ZM8 7C8.828 7 9.5 7.672 9.5 8.5C9.5 9.328 8.828 10 8 10C7.172 10 6.5 9.328 ' +
      '6.5 8.5C6.5 7.672 7.172 7 8 7ZM18 3H6C4.346 3 3 4.346 3 6V18C3 19.654 4.346 21 6 21H18C19.654 21 ' +
      '21 19.654 21 18V6C21 4.346 19.654 3 18 3Z"/></svg>';
    const $_user = store.state.user.userinfo;
    const $_email = $_user.email;
    const $_pat = store.state.shaderTool.currentPat;

    const curShader = computed(() => store.state.shaderTool.currentShader);

    watch(
      () => curShader.value.shader_id,
      () => {
        // console.log('watch curShader', shader);
        getHighlightList(1);
      }
    );

    const btnTexts = ['生成Highlight', '刷新Highlight'];
    const btnText = ref(btnTexts[0]);
    const picStat = ref(0); // 0 空  1 正在加载  2 加载完成
    const popVisible = ref(false);

    /**
         * {
                reference: {},//原始图片
                variant: {}  //变种图片
            }
        */
    const pics = ref([]);
    const picOris = ref([]);

    const picNo = ref(0);

    const getHighlightList = async (query) => {
      picStat.value = 1;
      const firstParams = {
        user: $_email,
        user_id: $_user.uid,
        pat: $_pat.pat_name,
        pid: curShader.value.shader_id,
        query: query || 0,
        width: 240,
        height: 136,
      };
      const firstRes = await api.getHighlightList(firstParams);
      if (!firstRes) return;
      if (firstRes.code == 0) {
        let _pics = [];
        if (firstRes.reference) {
          for (let i = 0; i < firstRes.reference.length; i++) {
            const _fileR = firstRes.reference[i];
            const _fileV = firstRes.variant[i];
            _pics.push({
              id: i,
              reference: {
                file: _fileR,
                url:
                  engineurl +
                  '/api/sopt/simplifier/getHighlightByFilename?filename=' +
                  _fileR +
                  '&pat=' +
                  $_pat.pat_name +
                  '&user=' +
                  $_email +
                  '&pid=' +
                  curShader.value.shader_id,
              },
              variant: {
                file: _fileV,
                url:
                  engineurl +
                  '/api/sopt/simplifier/getHighlightByFilename?filename=' +
                  _fileV +
                  '&pat=' +
                  $_pat.pat_name +
                  '&user=' +
                  $_email +
                  '&pid=' +
                  curShader.value.shader_id,
              },
            });
          }
        }
        pics.value = _pics;
        if (_pics.length > 0) {
          picStat.value = 2;
          popVisible.value = false;
          const params = {
            user: $_email,
            user_id: $_user.uid,
            query: query || 0,
            pat: $_pat.pat_name,
            pid: curShader.value.shader_id,
          };
          const res = await api.getHighlightList(params);
          if (!res) return;
          if (res.code != 0) {
            message.error('正在生成Highlight原图:' + res.detail);
            return;
          }
          let _picoris = [];
          for (let i = 0; i < res.reference.length; i++) {
            const _fileR = res.reference[i];
            const _fileV = res.variant[i];
            _picoris.push({
              id: i,
              reference: {
                file: _fileR,
                url:
                  engineurl +
                  '/api/sopt/simplifier/getHighlightByFilename?filename=' +
                  _fileR +
                  '&pat=' +
                  $_pat.pat_name +
                  '&user=' +
                  $_email +
                  '&pid=' +
                  curShader.value.shader_id,
              },
              variant: {
                file: _fileV,
                url:
                  engineurl +
                  '/api/sopt/simplifier/getHighlightByFilename?filename=' +
                  _fileV +
                  '&pat=' +
                  $_pat.pat_name +
                  '&user=' +
                  $_email +
                  '&pid=' +
                  curShader.value.shader_id,
              },
            });
          }
          picOris.value = _picoris;
        } else {
          picStat.value = 0;
          btnText.value = btnTexts[1];
          popVisible.value = false;
          message.success('创建获取Highlight任务成功！（需要几分钟等待）');
        }
      } else if (query == 1 && firstRes.code == 12) {
        picStat.value = 0;
        btnText.value = btnTexts[0];
        message.info('还未生成Highlight，点击按钮开始生成。');
        popVisible.value = true;
      } else if (query == 1 && firstRes.code == 3) {
        picStat.value = 0;
        btnText.value = btnTexts[1];
      } else if (query != 1) {
        picStat.value = 0;
        btnText.value = btnTexts[1];
        message.warning('正在生成Highlight: ' + firstRes.detail);
      } else {
        picStat.value = 0;
        console.log('正在生成Highlight:', firstRes.detail);
      }
    };

    const carouselChange = (val) => {
      // console.log('carouselChange', val);
      picNo.value = val;
    };

    const popHide = () => {
      popVisible.value = false;
    };

    const openPic = () => {
      const refdom = document.querySelector(
        '#rays_tuner_simplifytool_contrast img'
      );
      refdom.click();
      // console.log('refdom', refdom);
    };

    getHighlightList(1);
    onMounted(() => {
      // console.log(process.env.NODE_ENV);
    });
    onBeforeUnmount(() => {});

    return {
      picStat,
      btnText,
      popVisible,
      pics,
      picOris,
      picNo,
      imgSvg,
      getHighlightList,
      carouselChange,
      popHide,
      openPic,
    };
  },
});
</script>

<style scoped lang="scss">
.rays_tuner_simplifytool_highlight {
  position: relative;
  width: 240px;
  height: 136px;
  background-color: #3c3c3c;
  border-radius: 4px;
  .pic_list {
    .icon {
      width: 16px;
      height: 16px;
      color: #fff;
    }
    & :deep(.slick-slide) {
      text-align: center;
      height: 136px;
      line-height: 136px;
      overflow: hidden;
    }

    & :deep(.slick-arrow.custom-slick-arrow) {
      width: 16px;
      height: 16px;
      font-size: 16px;
      color: #fff;
      border-radius: 8px;
      background-color: rgba(0, 0, 0, 0.6);
      z-index: 100;
    }
    & :deep(.custom-slick-arrow:before) {
      display: none;
    }
    & :deep(.custom-slick-arrow:hover) {
      opacity: 0.8;
    }

    & :deep(.slick-slide h3) {
      color: #fff;
    }
  }
  .pic_bot {
    position: absolute;
    width: 100%;
    height: 24px;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 0px 0px 4px 4px;
    .pic_title {
      padding-right: 8px;
      padding-left: 8px;
      font-size: 12px;
      font-family: PingFang SC, PingFang SC-Bold;
      font-weight: 500;
      text-align: center;
      color: #ffffff;
      line-height: 20px;
      .icon {
        float: right;
        width: 16px;
        height: 16px;
        margin-top: 4px;
        color: rgba(255, 255, 255, 0.8);
        cursor: pointer;
        outline: none;
      }
    }
  }
  .pic_none {
    width: 100%;
    height: 100%;
    .pic_loading {
      margin-left: 110px;
      margin-top: 58px;
    }
    .none_btn {
      width: 130px;
      height: 36px;
      margin-left: 55px;
      margin-top: 52px;
      color: #fff;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      background: #604bdc;
      border-color: #604bdc;
      border-radius: 4px;
    }
  }
  .pop_con {
    position: absolute;
    height: 136px;
    top: 0;
  }
  .contrast {
    position: absolute;
    top: -500px;
  }
}
</style>
<style lang="scss">
.simplifytool_highlight_pop {
  .ant-popover-content {
    .ant-popover-arrow {
      border-top-color: rgba(0, 0, 0, 0.8);
      border-right-color: rgba(0, 0, 0, 0.8);
    }
    .ant-popover-inner {
      height: 30px;
      width: 265px;
      .ant-popover-inner-content {
        padding: 4px 16px 4px 16px;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        text-align: CENTER;
        color: rgba(240, 240, 240, 0.8);
        line-height: 22px;
        .hide_btn {
          margin-left: 16px;
          color: #604bdc;
          text-decoration: underline;
          font-weight: 700;
        }
      }
    }
  }
}
</style>
