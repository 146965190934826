<template>
  <a-layout class="rays-tuner-simplifytool-view">
    <a-layout-content class="router_content">
      <div class="content_header" v-show="pageStat == 1">
        <div class="top">
          <span class="time">
            {{ curSimplifyTask.task_number || '' }}
          </span>
          <svg-icon style="margin-left: 18px" class="icon" icon-class="phone" />
          <span style="margin-right: 43px">{{
            curSimplifyTask.params.device_type || ''
          }}</span>
          <svg-icon class="icon" icon-class="fps" />
          <span
            >{{ curSimplifyTask.params.begin_frame || 0 }}-{{
              curSimplifyTask.params.end_frame || 0
            }}</span
          >
        </div>
        <div class="bot">
          <span style="margin-right: 59px"
            >分辨率: {{ curSimplifyTask.params.res_width || 0 }}x{{
              curSimplifyTask.params.res_height || 0
            }}</span
          >
          <span style="margin-right: 73px"
            >Step: {{ curSimplifyTask.params.measure_step || '' }}</span
          >
          <span style="margin-right: 73px">总耗时: {{ totleTime }}</span>
          <span style="margin-right: 53px"
            >提交时间: {{ curSimplifyTask.add_time || '' }}</span
          >
          <span style="margin-right: 53px"
            >开始时间: {{ curSimplifyTask.start_time || '' }}
          </span>
          <span>完成时间: {{ curSimplifyTask.end_time || '' }}</span>
        </div>
      </div>
      <div class="content_content" v-show="pageStat == 1">
        <div class="chart_top_bar">
          <span class="result">简化结果</span>
          <span class="num">变体数 {{ resTotal }}</span>
          <a-checkbox v-model:checked="onlyShowPareto" class="recommend_check"
            >仅显示推荐</a-checkbox
          >
          <a-form layout="inline" class="search_form">
            <a-form-item label="Error小于等于" v-bind="validateInfos.error">
              <a-input
                v-model:value="modelRef.error"
                size="small"
                @pressEnter="onSearch"
                placeholder="回车进行筛选"
              />
            </a-form-item>
            <a-form-item label="Time小于等于" v-bind="validateInfos.time">
              <a-input
                v-model:value="modelRef.time"
                size="small"
                @pressEnter="onSearch"
                placeholder="回车进行筛选"
              />
            </a-form-item>
            <a-form-item>
              <a-button size="small" @click.prevent="onSearch">筛选</a-button>
            </a-form-item>
          </a-form>
        </div>
        <!-- <div class="introduce">从图中选取变体，提交筛选任务，查看详细简化结果</div> -->
        <!-- <div id="shader_simplifytool_echart" class="simplify_chart"></div> -->
        <result-charts
          :mdata="chartData"
          @submit-point="submitPoint"
          :update="chartUpdate"
          :taskData="screenTaskList"
          :onlyShowPareto="onlyShowPareto"
          :search="searchChart"
        />
      </div>
      <div class="content_none" v-show="pageStat == 0">
        <div class="empty-con">
          <div class="empty-icon"></div>
          <div class="empty-tip">暂无任务</div>
          <a-button class="empty-upload" @click="createTask">
            创建新任务
          </a-button>
        </div>
      </div>
    </a-layout-content>
    <a-layout-sider class="right_slider" width="288">
      <div class="right_top">
        <simplify-highlight />
      </div>
      <div class="right_bot">
        <screen-task-list
          stitle="筛选的变体"
          list-id="shader_simplifytool_screentask_scroll"
        />
      </div>
    </a-layout-sider>
  </a-layout>
</template>
<script>
import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
  computed,
  watch,
  reactive,
} from 'vue';
import router from '@/routes';
import store from '@/vuex/store';
import { message, Form } from 'ant-design-vue';
import ResultCharts from '@/components/charts/SimplifyCharts.vue';
import SimplifyHighlight from './SimplifyHighlight.vue';
import ScreenTaskList from './ScreenTaskList.vue';
import api from '@/api/shader-tools';

const useForm = Form.useForm;

const calcTimes = function (start, end) {
  // console.log('calcTimes', start, end);
  let _start = new Date(start).getTime();
  let _end = new Date(end).getTime();
  let _res = _end - _start;
  if (_res > 0) {
    let resStr = '';
    _res = Math.round(_res / 1000);
    let tmp = Math.floor(_res / 60);
    resStr = '’' + (_res - tmp * 60) + resStr;
    _res = tmp;
    tmp = Math.floor(_res / 60);
    resStr = '’' + (_res - tmp * 60) + resStr;
    _res = tmp;
    tmp = Math.floor(_res / 60);
    resStr = _res - tmp * 60 + resStr;
    _res = tmp;
    // console.log('calcTimes', resStr);
    return resStr;
  } else {
    return '0’0’0';
  }
};
export default defineComponent({
  name: 'SimplifyToolView',
  components: {
    ResultCharts,
    SimplifyHighlight,
    ScreenTaskList,
  },
  setup() {
    // console.log('SimplifyToolView');
    const $_user = store.state.user.userinfo;
    const $_email = $_user.email;
    const $_pat = store.state.shaderTool.currentPat;
    const chartData = ref({});

    const curShader = computed(
      () => store.state.shaderTool.currentShader || {}
    );

    const curSimplifyTask = computed(
      () => store.state.shaderTool.currentShaderSimplifyTask || { params: {} }
    );
    const totleTime = ref(
      calcTimes(
        curSimplifyTask.value.start_time,
        curSimplifyTask.value.end_time
      )
    );
    const resTotal = ref(0);
    let mountedRes = 0;

    const chartUpdate = ref(0);

    watch(
      () => curSimplifyTask.value.task_number,
      () => {
        console.log(
          'watch curSimplifyTask task_number',
          curSimplifyTask.value.shader_id,
          curShader.value.shader_id
        );
        totleTime.value = calcTimes(
          curSimplifyTask.value.start_time,
          curSimplifyTask.value.end_time
        );
        if (curSimplifyTask.value.shader_id == curShader.value.shader_id) {
          getSimplifyRes();
        }
      }
    );

    watch(
      () => curSimplifyTask.value.end_time,
      () => {
        // console.log('watch curSimplifyTask end_time');
        totleTime.value = calcTimes(
          curSimplifyTask.value.start_time,
          curSimplifyTask.value.end_time
        );
      }
    );

    // watch(()=>curShader.value.shader_id, () => {
    //     console.log('watch curShader');
    //     getSimplifyRes();
    // });

    const pageStat = computed(
      () => store.state.shaderTool.shaderSimplify.pageStat
    );
    watch([pageStat, () => curShader.value.shader_id], () => {
      console.log('watch pageStat curShader', pageStat.value);
      if (pageStat.value == 1) {
        chartUpdate.value = new Date().getTime();
        // console.log('chartUpdate',chartUpdate.value);
        // totleTime.value = calcTimes(curSimplifyTask.value.start_time, curSimplifyTask.value.end_time);
        if (
          mountedRes == 0 &&
          curSimplifyTask.value.shader_id == curShader.value.shader_id
        ) {
          getSimplifyRes();
        }
        mountedRes = 0;
      }
    });

    const screenTaskList = computed(
      () => store.state.shaderTool.screenTaskList || []
    );

    const getSimplifyRes = async () => {
      const _taskid = curSimplifyTask.value.task_number;
      if (!_taskid) {
        return;
      }
      const params = {
        user: $_email,
        pat: $_pat.pat_name,
        pid: curShader.value.shader_id,
        simpl_task_id: _taskid,
      };
      const res = await api.getChart(params);
      if (!res) return;
      if (res.code != 0) {
        message.error('获取简化任务数据失败：' + res.detail);
        return;
      }
      let _data = JSON.parse(res.result_list);
      chartData.value = _data;
      resTotal.value = 0;
      let _point = _data.points;
      for (let i in _point) {
        let _tmp = _point[i] || [];
        resTotal.value += _tmp.length;
      }
    };

    const createTask = () => {
      router.push({
        path: '/shaderhome/simplify/create',
      });
    };

    const addReplayTestTask = async (variant, error, time) => {
      const _param = curSimplifyTask.value.params;
      const params = {
        pat: $_pat.pat_name,
        user: $_email,
        user_id: $_user.uid,
        step: _param.measure_step,
        width: _param.res_width,
        height: _param.res_height,
        device_type: _param.device_type,
        simpl_task_id: curSimplifyTask.value.task_number,
        pid: curShader.value.shader_id,
        variant: variant,
        error: error,
        time: time,
        parent_id: curSimplifyTask.value.id,
      };
      const res = await api.addReplayTestTask(params);
      if (!res) return;
      if (res.code == 0) {
        message.success('添加(变体' + variant + ')筛选任务成功！', 4);
        store.commit('setShaderScreenTaskUpdate', new Date().getTime());
      } else {
        message.error('添加筛选任务数据失败：' + res.detail);
      }
      store.commit('setMailUpdateTime', new Date().getTime());
    };

    const submitPoint = (variant, param, hasTask) => {
      // console.log('submitPoint', variant, param, hasTask);
      if (hasTask) {
        message.error('该变体的筛选任务已存在！');
      } else {
        addReplayTestTask(variant, param.data[1], param.data[0]);
      }
    };

    const onlyShowPareto = ref(true);
    const modelRef = reactive({
      error: '',
      time: '',
    });
    const validateNumber = async (rule, value) => {
      // console.log('validateNumber', value, parseFloat(value), parseFloat(value) == value);
      if (parseFloat(value) != value && value !== '') {
        return Promise.reject('请输入数字');
      } else {
        return Promise.resolve();
      }
    };
    const rulesRef = reactive({
      error: [
        {
          validator: validateNumber,
        },
      ],
      time: [
        {
          validator: validateNumber,
        },
      ],
    });
    const { resetFields, validate, validateInfos } = useForm(
      modelRef,
      rulesRef
    );
    const searchChart = ref();
    const onSearch = () => {
      validate()
        .then(() => {
          console.log('onSearch', modelRef);
          searchChart.value = {
            maxTime: modelRef.time,
            maxError: modelRef.error,
          };
        })
        .catch((error) => {
          console.log('error', error);
        });
    };

    onMounted(() => {
      console.log(
        'SimplifyToolView',
        process.env.NODE_ENV,
        curSimplifyTask.value.shader_id,
        curShader.value.shader_id
      );
      if (
        curSimplifyTask.value.task_number &&
        curSimplifyTask.value.shader_id == curShader.value.shader_id
      ) {
        getSimplifyRes();
      }
      mountedRes++;
      // 初始化PAT列表scroll
      // shaderTaskDom = document.querySelector('#shader_screentool_task_scroll');
      // shaderTaskPs = new PerfectScrollbar(shaderTaskDom);
    });
    onBeforeUnmount(() => {
      // if (shaderTaskPs) {
      //     shaderTaskPs.destroy();
      //     shaderTaskPs = null;
      // }
      // shaderTaskDom = null;
    });

    return {
      chartData,
      chartUpdate,
      curSimplifyTask,
      screenTaskList,
      totleTime,
      resTotal,
      pageStat,
      onlyShowPareto,
      validateInfos,
      resetFields,
      validate,
      modelRef,
      searchChart,
      getSimplifyRes,
      submitPoint,
      createTask,
      onSearch,
    };
  },
});
</script>

<style scoped lang="scss">
.icon {
  width: 24px;
  height: 24px;
  color: #d8d8d8;
}

.router_content {
  border-right: 1px solid #d8d8d8;
  border-left: 1px solid #d8d8d8;
  background-color: #fff;
  .content_header {
    padding: 24px 24px 14px;
    border-bottom: 1px solid #f0f0f0;
    .top {
      font-size: 14px;
      color: #aaa;
      .time {
        position: relative;
        font-size: 16px;
        color: #222;
        padding-right: 24px;
        &:after {
          content: '';
          width: 1px;
          height: 16px;
          background-color: #f0f0f0;
          position: absolute;
          top: 50%;
          margin-top: -8px;
          right: 0px;
        }
      }
      .icon {
        margin-right: 9px;
        color: #aaa;
        vertical-align: -7px;
      }
    }
    .bot {
      margin-top: 18px;
      font-size: 14px;
      color: #666;
      display: inline-block;
      span {
        float: left;
      }
    }
  }

  .content_content {
    padding: 24px;
    height: calc(100% - 133px);
    .chart_top_bar {
      margin-bottom: 24px;
    }
    .result {
      font-size: 16px;
      color: #222;
    }
    .num {
      margin-left: 16px;
      font-size: 12px;
      color: #604bdc;
      background: rgba(136, 119, 255, 0.1);
      border-radius: 2px;
      padding: 4px 8px;
    }
    .recommend_check {
      margin-left: 16px;
    }
    .search_form {
      float: right;
      margin-top: -2px;
      input {
        width: 120px;
      }
    }
    .introduce {
      font-size: 13px;
      color: #aaa;
      margin-top: 12px;
    }
    .simplify_chart {
      width: 100%;
      height: calc(100% - 57px);
      background: rgba(55, 24, 252, 0.1);
    }
  }

  .content_none {
    position: relative;
    height: 100%;
    width: 100%;
    .empty-con {
      position: absolute;
      left: calc(50% - 90px);
      top: calc(50% - 80px);
      width: 160px;
      height: 182px;
      font-weight: 500;
      text-align: CENTER;
      .empty-icon {
        width: 79px;
        height: 79px;
        margin-left: 40px;
        background: url('~@/assets/Inbox.png') no-repeat;
        border: 1px dashed rgba(0, 0, 0, 0.2);
      }
      .empty-tip {
        margin-top: 8px;
        color: #666666;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Medium;
      }
      .empty-upload {
        width: 160px;
        height: 48px;
        margin-top: 24px;
        color: #fff;
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-Bold;
        background: #604bdc;
        border-color: #604bdc;
        border-radius: 4px;
      }
    }
  }
}
.right_slider {
  background-color: #fff;
  .right_top {
    height: 168px;
    padding: 16px 24px 16px 24px;
    border-bottom: 1px solid #d8d8d8;
  }
  .right_bot {
    height: calc(100% - 168px);
  }
}
</style>
<style lang="scss">
.rays-tuner-pat-edit-menu .ant-dropdown-menu-item:hover {
  color: #ff2222;
}
</style>
