<template>
  <div class="chart_con">
    <div
      id="shader_simplifytool_echart"
      style="width: 100%; height: 100%"
    ></div>
    <a-popconfirm
      :title="'提交(变体' + variant + ')获取详细结果？'"
      ok-text="提交"
      cancel-text="取消"
      overlayClassName="shader_simplifytool_echart_confirm"
      :overlayStyle="confirmStyle"
      @confirm="confirm"
      @cancel="cancel"
      :visible="confirmShow"
      :destroyTooltipOnHide="true"
    >
      <template #icon
        ><svg-icon class="icon" icon-class="modal_success"
      /></template>
      <a href="#" class="chart_confirm" :style="confirmStyle"></a>
    </a-popconfirm>
  </div>
</template>

<script>
// import Echarts from 'echarts';
import * as Echarts from 'echarts';

let myChart = null;

export default {
  name: 'SimplifyCharts',
  data() {
    return {
      Origin: [],
      Loop: [],

      Pareto0: [],
      Pareto1: [],
      Pareto2: [],

      LoopUsed: [],

      confirmShow: false,
      confirmStyle: {},
      queryShader: {},
      variant: '',
    };
  },
  props: {
    mdata: {
      type: Object,
      // default: {}
    },
    taskData: {
      type: Array,
      // default: {}
    },
    update: {
      type: Number,
    },
    onlyShowPareto: {
      type: Boolean,
    },
    /**
        {
          maxTime,
          maxError
        }
        */
    search: {
      type: Object,
    },
    // queryShader:{
    //    type: Object,
    // }
  },
  created() {
    this.$nextTick(() => {
      // console.log('created echart')
      myChart = Echarts.init(
        document.getElementById('shader_simplifytool_echart')
      );
      this.loadEchart();
      // this.myChart.on('mouseup',{seriesName:'Loop0'},(params) => {
      //   this.queryShader = params
      //   console.log("this.queryShader",this.queryShader)
      //   this.$emit("updateMyShader",this.queryShader)
      // })
      this.clickPoint();
    });
  },
  mounted() {
    // var myChart=charts.init(document.getElementById('echart'));
    // console.log("myChart"+myChart);
    window.onresize = function () {
      myChart.resize();
    };
    this.$nextTick(() => {
      this.updateShowPareto(this.onlyShowPareto);
    });
  },
  unmounted() {
    myChart.dispose();
  },
  methods: {
    confirm() {
      // console.log('confirm', this.queryShader);
      this.confirmShow = false;
      this.$emit('submitPoint', this.variant, this.queryShader);
    },
    cancel() {
      // console.log('cancel');
      this.confirmShow = false;
    },
    clickPoint() {
      myChart.on('mouseup', 'series', (params) => {
        // console.log('chart mouseup',params, this.LoopUsed);
        if (params.data[0] == 0 && params.data[1] == 0) {
          return;
        }
        const _event = params.event;
        this.confirmStyle = {
          left: _event.offsetX + 'px',
          top: _event.offsetY + 'px',
        };
        //= 'left:' + _event.offsetX + 'px;top:' + _event.offsetY + 'px;';
        // console.log(this.confirmStyle);
        this.variant =
          '_' + (params.data[3] || 0) + '_' + (params.data[2] || 0) + 'm';
        this.queryShader = params;

        for (let i = 0; i < this.LoopUsed.length; i++) {
          if (
            this.LoopUsed[i][2] == params.data[2] &&
            this.LoopUsed[i][3] == params.data[3]
          ) {
            this.$emit('submitPoint', this.variant, this.queryShader, true);
            return;
          }
        }
        this.confirmShow = true;
      });
      // this.myChart.on('mouseup',{seriesName:'Loop0'},(params) => {
      //     this.queryShader = params
      //     this.logInfo(params)
      //     this.$emit("updateMyShader",this.queryShader)
      // })
      // this.myChart.on('mouseup',{seriesName:'Loop1'},(params) => {
      //     this.queryShader = params
      //     this.logInfo(params)
      //     this.$emit("updateMyShader",this.queryShader)
      // })
      // this.myChart.on('mouseup',{seriesName:'Loop2'},(params) => {
      //     this.queryShader = params
      //     this.logInfo(params)
      //     this.$emit("updateMyShader",this.queryShader)
      // })
      // this.myChart.on('mouseup',{seriesName:'Pareto0'},(params) => {
      //     this.queryShader = params
      //     this.logInfo(params)
      //     this.$emit("updateMyShader",this.queryShader)
      // })
      // this.myChart.on('mouseup',{seriesName:'Pareto1'},(params) => {
      //     this.queryShader = params
      //     this.logInfo(params)
      //     this.$emit("updateMyShader",this.queryShader)
      // })
      // this.myChart.on('mouseup',{seriesName:'Pareto2'},(params) => {
      //     this.queryShader = params
      //     this.logInfo(params)
      //     this.$emit("updateMyShader",this.queryShader)
      // })
      // this.myChart.on('mouseup',{seriesName:'Origin'},(params) => {
      //     this.queryShader = params
      //     this.logInfo(params)
      //     this.$emit("updateMyShader",this.queryShader)
      // })
    },

    // logInfo(params){
    //     console.log("time:",(Math.round((params.data[0]-this.Origin[0][0])*10000)/100.00).toFixed(2)+'%',"error:",params.data[1])
    // },

    formatData() {
      this.Loop = [];
      this.Pareto0 = [];
      this.Pareto1 = [];
      this.Pareto2 = [];
      this.Origin = [];

      this.LoopUsed = [];

      if (!this.mdata.origin) {
        return;
      }
      let p = [this.mdata.origin.time, this.mdata.origin.error];

      this.Origin.push([0, 0]);

      const _tmploop = {};

      // console.log("this.origin:",this.Origin)
      for (let index in this.mdata.points) {
        // console.log(this.mdata.points[index])
        let thisLoop = [];
        const _first = this.mdata.points[index][0] || false;
        for (let inside in this.mdata.points[index]) {
          // console.log(this.mdata.points[index][inside])
          const _point = this.mdata.points[index][inside];
          const _time = _point.time - p[0];
          const _error = _point.error - p[1];

          if (
            this.search &&
            this.search.maxTime != '' &&
            _time > parseFloat(this.search.maxTime)
          ) {
            continue;
          }
          if (
            this.search &&
            this.search.maxError != '' &&
            _error > parseFloat(this.search.maxError)
          ) {
            continue;
          }
          thisLoop.push([
            _time,
            _error,
            _point.index,
            _point.loop,
            this.Origin[0][0],
          ]);
        }
        if (_first) {
          _tmploop[_first.loop] = thisLoop;
        }
        // this.Loop.push(thisLoop)
      }
      for (let i = 0; _tmploop[i]; i++) {
        this.Loop.push(_tmploop[i]);
      }

      for (let index in this.mdata.pareto) {
        // let temp = parseInt(index/10000)
        const _point = this.mdata.pareto[index];
        const _time = _point.time - p[0];
        const _error = _point.error - p[1];

        if (
          this.search &&
          this.search.maxTime !== '' &&
          _time > parseFloat(this.search.maxTime)
        ) {
          continue;
        }
        if (
          this.search &&
          this.search.maxError !== '' &&
          _error > parseFloat(this.search.maxError)
        ) {
          continue;
        }

        this.Pareto0.push([
          _time,
          _error,
          _point.index,
          _point.loop,
          this.Origin[0][0],
        ]);
        // if(temp == 0 ){
        //   this.Pareto0.push([_time, _error,index - 0,temp,this.Origin[0][0]])
        // }else if(temp == 1){
        //   this.Pareto1.push([_time, _error,index - 10000,temp,this.Origin[0][0]])
        // }else if(temp == 2){
        //   this.Pareto2.push([_time, _error,index - 20000,temp,this.Origin[0][0]])
        // }
      }
      this.Pareto0.sort(function (a, b) {
        return a[0] - b[0];
      });
      // this.Pareto1.sort(function(a,b){ return a[0] - b[0]});
      // this.Pareto2.sort(function(a,b){ return a[0] - b[0]});

      // console.log(this.Origin)
      // console.log(this.Loop)
      // console.log("Pareto0",this.Pareto0)
      const _tasks = JSON.parse(JSON.stringify(this.taskData));
      // console.log(_tasks);
      for (let i = 0; i < _tasks.length; i++) {
        inter: for (let index in this.mdata.points) {
          for (let inside in this.mdata.points[index]) {
            const _point = this.mdata.points[index][inside];
            const _vari = '_' + _point.loop + '_' + _point.index + 'm';
            if (_vari == _tasks[i].params.var_shader_name) {
              const _time = _point.time - p[0];
              const _error = _point.error - p[1];

              if (
                this.search &&
                this.search.maxTime !== '' &&
                _time > parseFloat(this.search.maxTime)
              ) {
                continue;
              }
              if (
                this.search &&
                this.search.maxError !== '' &&
                _error > parseFloat(this.search.maxError)
              ) {
                continue;
              }
              this.LoopUsed.push([
                _time,
                _error,
                _point.index,
                _point.loop,
                this.Origin[0][0],
              ]);
              _tasks.splice(i, 1);
              i--;
              break inter;
            }
          }
        }
      }
    },
    loadEchart() {
      // console.log('loadEchart',myChart);
      // this.myChart = Echarts.init(document.getElementById("echart"));
      myChart.setOption({
        title: {
          text: '从图中选取变体，提交筛选任务，查看详细简化结果',
          textStyle: {
            fontSize: 13,
            color: '#aaa',
          },
        },
        tooltip: {
          trigger: 'item',
          axisPointer: {
            type: 'cross',
          },
          formatter: function (params) {
            // console.log("params:",params)
            if (typeof params.value[2] == 'undefined') {
              return (
                '<p>index: ' +
                'origin' +
                '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;loop: ' +
                'origin' +
                '</p>' +
                '<p>time: ' +
                (Math.round(params.value[0] * 10000) / 100.0).toFixed(2) +
                '%' +
                '</p><p>error: ' +
                params.value[1] +
                '</p>'
              );
            }
            return (
              '<p>index: ' +
              params.value[2] +
              '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;loop: ' +
              params.value[3] +
              '</p>' +
              '<p>time: ' +
              (
                Math.round((params.value[0] - params.value[4]) * 10000) / 100.0
              ).toFixed(2) +
              '%' +
              '</p><p>error: ' +
              params.value[1] +
              '</p>'
            );
            // (Math.round((params.value[0]-this.Origin[0][0])*10000)/100.00).toFixed(2)+'%'
          },
        },
        xAxis: {
          name: 'time',
          type: 'value',
        },
        yAxis: {
          name: 'error',
          type: 'value',
        },
        legend: {
          right: 0,
          data: [
            {
              name: 'Used',
              itemStyle: {
                color: '#3c3c3c',
              },
            },
            {
              name: 'Loop0',
              itemStyle: {
                color: '#0086ff',
              },
            },
            {
              name: 'Loop1',
              itemStyle: {
                color: '#42c67e',
              },
            },
            {
              name: 'Loop2',
              itemStyle: {
                color: '#ffb800',
              },
            },
            {
              name: 'Pareto',
              itemStyle: {
                color: '#0086ff',
              },
              // },{
              //   name: "Pareto0",
              //   itemStyle:{
              //     color: '#0086ff'
              //   }
              // },{
              //   name: "Pareto1",
              //   itemStyle:{
              //     color: '#42c67e'
              //   }
              // },{
              //   name: "Pareto2",
              //   itemStyle:{
              //     color: '#ffb800'
              //   }
            },
          ],
        },
        dataZoom: [
          {
            type: 'slider',
            xAxisIndex: 0,
            // start: 10,
            // end: 60
          },
          {
            type: 'inside',
            xAxisIndex: 0,
            filterMode: 'none',
            // start: 10,
            // end: 60
          },
          {
            type: 'slider',
            yAxisIndex: 0,
            // start: 30,
            // end: 80
          },
          {
            type: 'inside',
            yAxisIndex: 0,
            filterMode: 'none',
            // start: 30,
            // end: 80
          },
        ],
        series: [
          {
            type: 'scatter',
            name: 'Loop0',
            color: '#0086ff',
            // symbolSize: 12,
            data: this.Loop[0],
          },
          {
            type: 'scatter',
            name: 'Loop1',
            color: '#42c67e',
            // symbolSize: 12,
            data: this.Loop[1],
          },
          {
            type: 'scatter',
            name: 'Loop2',
            color: '#ffb800',
            // symbolSize: 12,
            data: this.Loop[2],
          },
          {
            type: 'scatter',
            symbolSize: 12,
            name: 'Origin',
            color: '#3c3c3c',
            data: this.Origin,
          },
          {
            type: 'line',
            name: 'Pareto',
            color: '#0086ff',
            symbolSize: 20,
            symbol: 'circle',
            itemStyle: {
              opacity: 0.5,
            },
            data: this.Pareto0,
          },
          // {
          //   type: "line",
          //   name: "Pareto0",
          //   color: '#0086ff',
          //   symbolSize : 20,
          //   symbol:'circle',
          //   itemStyle: {
          //     opacity: 0.5
          //   },
          //   data: this.Pareto0
          // },
          // {
          //   type: "line",
          //   name: "Pareto1",
          //   color: '#42c67e',
          //   symbolSize : 20,
          //   symbol:'circle',
          //   itemStyle: {
          //     opacity: 0.5
          //   },
          //   data: this.Pareto1
          // },
          // {
          //   type: "line",
          //   name: "Pareto2",
          //   color: '#ffb800',
          //   symbolSize : 20,
          //   symbol:'circle',
          //   itemStyle: {
          //     opacity: 0.5
          //   },
          //   data: this.Pareto2
          // },
          {
            type: 'scatter',
            name: 'Used',
            color: '#3c3c3c',
            // itemStyle: {
            //   opacity: 0.5
            // },
            symbol: 'pin',
            symbolSize: 20,
            data: this.LoopUsed,
          },
        ],
      });
    },
    updateEchart() {
      this.formatData();
      this.loadEchart();
      myChart.resize();
      this.$nextTick(() => {
        myChart.resize();
        // this.$nextTick(()=>{
        //   myChart.resize();
        // });
      });
    },
    updateShowPareto(show) {
      if (show && myChart) {
        myChart.dispatchAction({ type: 'legendUnSelect', name: 'Loop0' });
        myChart.dispatchAction({ type: 'legendUnSelect', name: 'Loop1' });
        myChart.dispatchAction({ type: 'legendUnSelect', name: 'Loop2' });
      } else {
        myChart.dispatchAction({ type: 'legendSelect', name: 'Loop0' });
        myChart.dispatchAction({ type: 'legendSelect', name: 'Loop1' });
        myChart.dispatchAction({ type: 'legendSelect', name: 'Loop2' });
      }
    },
  },
  computed: {
    chartData() {
      const { mdata, taskData } = this;
      return {
        mdata,
        taskData,
      };
    },
  },
  watch: {
    chartData(newVal) {
      // console.log('watch chartData', newVal, newVal.mdata.header)
      // console.log('new:',newVal)
      // console.log('old:',oldVal)
      if (!newVal.mdata.header) {
        return;
      }
      this.updateEchart();
    },
    update() {
      // console.log('watch upadate');
      this.$nextTick(() => {
        myChart.resize();
      });
    },
    onlyShowPareto(newVal) {
      // console.log('watch onlyShowPareto',newVal);
      this.updateShowPareto(newVal);
    },
    search() {
      console.log('watch chart search', this.search);
      this.updateEchart();
    },
  },
};
</script>
<style scoped>
.chart_con {
  position: relative;
  height: calc(100% - 29px);
  width: 100%;
  margin-top: 4px;
}
.chart_confirm {
  position: absolute;
  width: 0;
  height: 0;
  background-color: blue;
}
.icon {
  width: 24px;
  height: 24px;
  float: left;
  margin-right: 10px;
}
</style>
<style>
/* .shader_simplifytool_echart_confirm > .ant-popover-content{
    width: 420px;
} */
</style>
